<template>
  <div id="screenshotMobile-wrap" >
   <tool    ref="tool">
    <template slot="title">Mobile品专截图</template>
  </tool>
   <task    ref="task"    :code="code"></task>
  </div>
</template>

<script>
import tool from '../../components/Tool.vue'
import task from '../../components/TaskList.vue'
import { authorityMixin } from '../../utils/mixin'
import { createMobiReq } from '../../api/auth.js'
export default {
  name: 'screenshotMobile',
  components: {
   
  },
  mixins: [authorityMixin],
  components: {
    tool,
    task
  },
  data () {
    return {
    code:'getMobiReqs'
    }
  },
  watch: {
   
  },
  created () {
   
  },
   methods: {
    handleSubmit(){
      let keyWords =  this.$refs.tool.keyWords
       if(!(!keyWords||(keyWords&&keyWords.length===0))){
      let data ={
        uid:this.loginUser.uid,
        name:`Mobile品专截图`,
        keywords:keyWords.split('\n')
      }
      createMobiReq(data,{ simplify: false }).then(res=>{
      this.$message.success("任务提交成功")
      })
      }
      else{
        this.$message.error('请输入种子词,每一行一个词')
      }
    }
  }
}

</script>
<style lang='less'>
  #screenshotMobile-wrap {
 display: flex;
      height: 100%;
      width: 100%;
      background-color: #fff;
  }
</style>
